<template>
    <footer id="footer" class="footer">

        <div class="container footer-top">
            <div class="row gy-4">
                <div class="col-lg-6 col-md-12 footer-about">
                    <NuxtLinkLocale to="/" class="logo d-flex align-items-center">
                        <nuxt-img src="/images/smallsize_tagline_on_transparent_by_logaster.png" alt="logaster" class style="height: 116px; width: 198px;" loading="lazy" format="webp"/>
                    </NuxtLinkLocale>
                    <p>{{ $t('footer_sub') }}</p>
                    <div class="social-links d-flex mt-4" v-if="dataInfo">
                        <NuxtLink :to="`tel:${dataInfo?.phone ?? ''}`" aria-label="Phone"><i class="bi bi-phone"></i></NuxtLink>
                        <NuxtLink :to="dataInfo.facebook" target="_blank" aria-label="Facebook"><i
                                class="bi bi-facebook"></i></NuxtLink>
                        <NuxtLink :to="`mailto:${dataInfo?.email ?? ''}`" aria-label="Email"><i class="bi bi-envelope"></i></NuxtLink>
                        <NuxtLink :to="dataInfo.link_address" target="_blank" aria-label="Address"><i class="bi bi-map"></i>
                        </NuxtLink>
                    </div>
                </div>

                <div class="col-lg-3 col-6 footer-links">
                    <h1>{{ $t('footer_tab_link') }}</h1>
                    <ul>
                        <li v-for="(item, index) in menu">
                            <template v-if="item.data">
                                <NuxtLinkLocale :to="item.url" :data="item.data" @click="scrollOption(item.data)"
                                    :class="{ 'active': index === 0 }">{{
                        item.title }}</NuxtLinkLocale>
                            </template>
                            <template v-else>
                                <NuxtLinkLocale :to="item.url">{{
                        item.title }}</NuxtLinkLocale>
                            </template>
                        </li>

                    </ul>
                </div>



                <div class="col-lg-2 col-md-12 footer-contact text-md-start">
                    <h1>{{ $t('footer_tab_contact') }}</h1>
                    <p v-if="dataInfo">{{ dataInfo?.address ?? '' }}</p>
                    <p class="mt-4">
                        <strong>{{ $t('contact_phone') }}: </strong>
                        <span v-if="dataInfo">
                            <NuxtLink :to="`tel:${dataInfo?.phone ?? ''}`">{{ dataInfo?.phone ?? '' }}</NuxtLink>
                        </span>
                    </p>
                    <p class="mt-4">
                        <strong>Email: </strong>
                        <span v-if="dataInfo">
                            <NuxtLink :to="`mailto:${dataInfo?.email ?? ''}`">{{ dataInfo?.email ?? '' }}</NuxtLink>
                        </span>
                    </p>

                </div>

            </div>
        </div>

        <div class="container copyright text-center mt-4">
            <p>&copy; <span>Copyright</span>
                <NuxtLinkLocale to="https://nextcore.vn/">&nbsp;Nextcore</NuxtLinkLocale> <span>All Rights
                    Reserved</span>
            </p>
        </div>

    </footer>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useInfoStore } from '~/stores/info/info-store'
const infoStore = useInfoStore()
const { dataInfo } = storeToRefs(infoStore)
const { t, locale } = useI18n()

watch(locale, (_) => {
    infoStore.setDataInfoByLang(locale.value)
    menu.value = [
        {
            url: "/",
            data: "#home",
            title: t('menu_home')
        },
        {
            url: "/",
            data: "#about",
            title: t('menu_about')
        },
        {
            url: "/",
            data: "#technology",
            title: t('menu_technology')
        },
        {
            url: "/",
            data: "#messages",
            title: t('menu_message')
        },
        {
            url: "/",
            data: "#team",
            title: t('menu_team')
        },
        {
            url: "/blog",
            data: "blog",
            title: 'Blog'
        }
    ]
})


const menu = ref([
    {
        url: "/",
        data: "#home",
        title: t('menu_home')
    },
    {
        url: "/",
        data: "#about",
        title: t('menu_about')
    },
    {
        url: "/",
        data: "#technology",
        title: t('menu_technology')
    },
    {
        url: "/",
        data: "#messages",
        title: t('menu_message')
    },
    {
        url: "/",
        data: "#team",
        title: t('menu_team')
    },
    {
        url: "/blog",
        data: "blog",
        title: 'Blog'
    },
])

const scrollOption = (classTag: any) => {
    const scroll = document.querySelector(classTag) as HTMLElement
    if (scroll) {
        scroll?.scrollIntoView({ behavior: 'smooth' });
    }
}

</script>
