<template>
    <header id="header" class="header fixed-top d-flex align-items-center">
        <div class="container-fluid d-flex align-items-center justify-content-between">

            <NuxtLinkLocale to="/" class="logo d-flex align-items-center me-auto me-xl-0">
                <nuxt-img src="/images/logo.png" alt="logo" preload loading="lazy" fetchpriority="high" format="webp"/>
            </NuxtLinkLocale>
            <nav id="navmenu" class="navmenu pe-md-3">
                <ul>
                    <li v-for="(item, index) in menu">
                        <template v-if="item.data">
                            <NuxtLinkLocale :to="item.url" :data="item.data" @click="scrollOption(item.data)"
                                :class="{ 'active': index === 0 }">{{
                        item.title }}</NuxtLinkLocale>
                        </template>
                        <template v-else>
                            <NuxtLinkLocale :to="item.url">{{
                        item.title }}</NuxtLinkLocale>
                        </template>
                    </li>

                </ul>
                <div class="custom-lang">
                    <div v-for="(lang, index) in LANGUAGE" :key="index">
                        <img @click="setLocale(lang)" :src="`/images/lang/${lang}.png`" :alt="lang">
                    </div>
                </div>

                <i class="mobile-nav-toggle d-xl-none bi bi-list"></i>
            </nav>
        </div>
    </header>
</template>
<script setup lang="ts">
import { LANGUAGE } from '~/constants'
const { t, setLocale, locale } = useI18n()

const menu = ref([
    {
        url: "/",
        data: "#home",
        title: t('menu_home')
    },
    {
        url: "/",
        data: "#about",
        title: t('menu_about')
    },
    {
        url: "/",
        data: "#technology",
        title: t('menu_technology')
    },
    {
        url: "/",
        data: "#messages",
        title: t('menu_message')
    },
    {
        url: "/",
        data: "#team",
        title: t('menu_team')
    },
    {
        url: "/blog",
        data: "blog",
        title: 'Blog'
    },
    {
        url: "/",
        data: "#contact",
        title: t('menu_contact')
    }
])


watch(locale, (_) => {
    menu.value = [
    {
        url: "/",
        data: "#home",
        title: t('menu_home')
    },
    {
        url: "/",
        data: "#about",
        title: t('menu_about')
    },
    {
        url: "/",
        data: "#technology",
        title: t('menu_technology')
    },
    {
        url: "/",
        data: "#messages",
        title: t('menu_message')
    },
    {
        url: "/",
        data: "#team",
        title: t('menu_team')
    },
    {
        url: "/blog",
        data: null,
        title: 'Blog'
    },
    {
        url: "/",
        data: "#contact",
        title: t('menu_contact')
    }
]
})

const scrollOption = (classTag: any) => {
    setTimeout(() => { 
        const scroll = document.querySelector(classTag) as HTMLElement
        if (scroll) {
            scroll?.scrollIntoView({ behavior: 'smooth' });
        }
     }, 100)
}

</script>
